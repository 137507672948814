exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-gas-electricity-guides-business-tsx": () => import("./../../../src/pages/gas-electricity/guides/business.tsx" /* webpackChunkName: "component---src-pages-gas-electricity-guides-business-tsx" */),
  "component---src-pages-gas-electricity-guides-distribution-companies-tsx": () => import("./../../../src/pages/gas-electricity/guides/distribution/companies.tsx" /* webpackChunkName: "component---src-pages-gas-electricity-guides-distribution-companies-tsx" */),
  "component---src-pages-gas-electricity-guides-distribution-index-tsx": () => import("./../../../src/pages/gas-electricity/guides/distribution/index.tsx" /* webpackChunkName: "component---src-pages-gas-electricity-guides-distribution-index-tsx" */),
  "component---src-pages-gas-electricity-guides-environment-tsx": () => import("./../../../src/pages/gas-electricity/guides/environment.tsx" /* webpackChunkName: "component---src-pages-gas-electricity-guides-environment-tsx" */),
  "component---src-pages-gas-electricity-guides-index-tsx": () => import("./../../../src/pages/gas-electricity/guides/index.tsx" /* webpackChunkName: "component---src-pages-gas-electricity-guides-index-tsx" */),
  "component---src-pages-gas-electricity-guides-meters-tsx": () => import("./../../../src/pages/gas-electricity/guides/meters.tsx" /* webpackChunkName: "component---src-pages-gas-electricity-guides-meters-tsx" */),
  "component---src-pages-gas-electricity-guides-schemes-tsx": () => import("./../../../src/pages/gas-electricity/guides/schemes.tsx" /* webpackChunkName: "component---src-pages-gas-electricity-guides-schemes-tsx" */),
  "component---src-pages-gas-electricity-guides-switching-tsx": () => import("./../../../src/pages/gas-electricity/guides/switching.tsx" /* webpackChunkName: "component---src-pages-gas-electricity-guides-switching-tsx" */),
  "component---src-pages-gas-electricity-guides-tariffs-tsx": () => import("./../../../src/pages/gas-electricity/guides/tariffs.tsx" /* webpackChunkName: "component---src-pages-gas-electricity-guides-tariffs-tsx" */),
  "component---src-pages-gas-electricity-index-tsx": () => import("./../../../src/pages/gas-electricity/index.tsx" /* webpackChunkName: "component---src-pages-gas-electricity-index-tsx" */),
  "component---src-pages-gas-electricity-news-tsx": () => import("./../../../src/pages/gas-electricity/news.tsx" /* webpackChunkName: "component---src-pages-gas-electricity-news-tsx" */),
  "component---src-pages-gas-electricity-providers-tsx": () => import("./../../../src/pages/gas-electricity/providers.tsx" /* webpackChunkName: "component---src-pages-gas-electricity-providers-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-tv-broadband-guides-billing-tsx": () => import("./../../../src/pages/tv-broadband/guides/billing.tsx" /* webpackChunkName: "component---src-pages-tv-broadband-guides-billing-tsx" */),
  "component---src-pages-tv-broadband-guides-business-tsx": () => import("./../../../src/pages/tv-broadband/guides/business.tsx" /* webpackChunkName: "component---src-pages-tv-broadband-guides-business-tsx" */),
  "component---src-pages-tv-broadband-guides-deals-tsx": () => import("./../../../src/pages/tv-broadband/guides/deals.tsx" /* webpackChunkName: "component---src-pages-tv-broadband-guides-deals-tsx" */),
  "component---src-pages-tv-broadband-guides-index-tsx": () => import("./../../../src/pages/tv-broadband/guides/index.tsx" /* webpackChunkName: "component---src-pages-tv-broadband-guides-index-tsx" */),
  "component---src-pages-tv-broadband-guides-network-tsx": () => import("./../../../src/pages/tv-broadband/guides/network.tsx" /* webpackChunkName: "component---src-pages-tv-broadband-guides-network-tsx" */),
  "component---src-pages-tv-broadband-guides-switching-tsx": () => import("./../../../src/pages/tv-broadband/guides/switching.tsx" /* webpackChunkName: "component---src-pages-tv-broadband-guides-switching-tsx" */),
  "component---src-pages-tv-broadband-guides-technical-tsx": () => import("./../../../src/pages/tv-broadband/guides/technical.tsx" /* webpackChunkName: "component---src-pages-tv-broadband-guides-technical-tsx" */),
  "component---src-pages-tv-broadband-index-tsx": () => import("./../../../src/pages/tv-broadband/index.tsx" /* webpackChunkName: "component---src-pages-tv-broadband-index-tsx" */),
  "component---src-pages-tv-broadband-news-tsx": () => import("./../../../src/pages/tv-broadband/news.tsx" /* webpackChunkName: "component---src-pages-tv-broadband-news-tsx" */),
  "component---src-pages-tv-broadband-providers-tsx": () => import("./../../../src/pages/tv-broadband/providers.tsx" /* webpackChunkName: "component---src-pages-tv-broadband-providers-tsx" */),
  "component---src-templates-all-products-news-standard-page-template-tsx": () => import("./../../../src/templates/AllProductsNewsStandardPage-template.tsx" /* webpackChunkName: "component---src-templates-all-products-news-standard-page-template-tsx" */),
  "component---src-templates-gas-electricity-guide-company-page-template-tsx": () => import("./../../../src/templates/GasElectricityGuideCompanyPage-template.tsx" /* webpackChunkName: "component---src-templates-gas-electricity-guide-company-page-template-tsx" */),
  "component---src-templates-gas-electricity-guide-standard-page-template-tsx": () => import("./../../../src/templates/GasElectricityGuideStandardPage-template.tsx" /* webpackChunkName: "component---src-templates-gas-electricity-guide-standard-page-template-tsx" */),
  "component---src-templates-gas-electricity-provider-main-page-template-tsx": () => import("./../../../src/templates/GasElectricityProviderMainPage-template.tsx" /* webpackChunkName: "component---src-templates-gas-electricity-provider-main-page-template-tsx" */),
  "component---src-templates-gas-electricity-provider-standard-page-template-tsx": () => import("./../../../src/templates/GasElectricityProviderStandardPage-template.tsx" /* webpackChunkName: "component---src-templates-gas-electricity-provider-standard-page-template-tsx" */),
  "component---src-templates-policy-template-tsx": () => import("./../../../src/templates/Policy-template.tsx" /* webpackChunkName: "component---src-templates-policy-template-tsx" */),
  "component---src-templates-tv-broadband-guide-standard-page-template-tsx": () => import("./../../../src/templates/TvBroadbandGuideStandardPage-template.tsx" /* webpackChunkName: "component---src-templates-tv-broadband-guide-standard-page-template-tsx" */),
  "component---src-templates-tv-broadband-provider-main-page-template-tsx": () => import("./../../../src/templates/TvBroadbandProviderMainPage-template.tsx" /* webpackChunkName: "component---src-templates-tv-broadband-provider-main-page-template-tsx" */),
  "component---src-templates-tv-broadband-provider-standard-page-template-tsx": () => import("./../../../src/templates/TvBroadbandProviderStandardPage-template.tsx" /* webpackChunkName: "component---src-templates-tv-broadband-provider-standard-page-template-tsx" */)
}

